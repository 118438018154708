import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import settings from './settings'
import library from 'bh-mod/store/library'
import { $api } from 'bh-mod'

Vue.use(Vuex)
const fileIcons = {
	'Image': {
		icon: 'file-image',
		color: 'black'
	},
	'Document': {
		icon: 'file-pdf',
		color: 'green',
	},
	'Video': {
		icon: 'video-camera',
		color: 'red',
	},
	'Unknown Type': {
		icon: 'file',
		color: "yellow"
	},
	'Compressed File': {
		icon: 'file',
		color: "yellow"
	},
	'Folder': {
		icon: 'folder',
		color: "gray"
	},
}
const transformFile = file => {
	let { mime } = file
	let type = 'Unknown Type'
	if (mime) {
		if (mime.includes('image')) type = 'Image'
		if (mime.includes('pdf') || mime.includes('msword') || mime.includes('office')) type = 'Document'
		if (mime.includes('rar') || mime.includes('zip')) type = 'Compressed File'
		if (mime.includes('video')) type = 'Video'
	} else if (file.items) {
		type = 'Folder'
	}

	file.Type = type
	file.Icon = fileIcons[type]

	return file
}

function generateFolderStructure(data, parentPath = '') {
	const result = [];

	if (!parentPath) {
		result.push({
			title: "Main",
			value: '/'
		})
	}
	data.forEach(d => {
		if (d.items) {
			const path = parentPath ? parentPath + '/' + d.name : d.name
			const child = generateFolderStructure(d.items, path)
			result.push({
				title: d.name,
				value: path,
				children: child
			})
		}
	})
	result.push({
		title: "+ New Folder",
		value: parentPath ? parentPath + '/__newFolder' : '__newFolder'
	})
	return result;
}

function generateFolderFiles(data, parentPath = '') {
	let result = parentPath ? {} : { '/': [] };

	data.forEach(d => {
		if (d.items) {
			const path = parentPath ? parentPath + '/' + d.name : d.name
			result[path] = d.items;
			const child = generateFolderFiles(d.items, path)
			result = { ...result, ...child }
		} else if (!parentPath) {
			result['/'].push(d)
		}
	})

	return result;
}

export default new Vuex.Store({
	modules: {
		user,
		settings,
		library
	},
	state: {
		instances: [],
		instance: null,
		hives: [],
		cats: [],
		uploadDialog: false,
		folders: [],
		files: {},
		currentFolder: '/',
		theApp: {},
		pageButtons: [],
		loading: false,
		crumbs: [],
		getStarted: false,
		showFile: {},
		allSettings: {},
		fileStructure: []
	},
	mutations: {
		RENAME_FOLDER: (state, { from, to }) => {
			state.files[from].forEach(file => {
				file.folder = to
			})
			Vue.set(state.files, to, state.files[from])
			delete state.files[from]
			if (state.currentFolder == from) {
				state.currentFolder = to
			}
		},
		BULK_MOVE(state, { files, folder }) {
			if (state.files[folder]) {
				state.files[folder] = state.files[folder].concat(files)
			} else {
				let newArray = files
				Vue.set(state.files, folder, newArray)
			}
			files.forEach(file => {
				let index = state.files[state.currentFolder].findIndex(x => x.id == file.id)
				if (index != -1) state.files[state.currentFolder].splice(index, 1)
			})
		},
		MOVE_FILES: (state, { file, folder }) => {
			if (state.files[folder]) {
				state.files[folder].push(file)
			}
			else {
				let newArray = [file]
				Vue.set(state.files, folder, newArray);
			}
			let deleteIndex = state.files[state.currentFolder].findIndex(x => x.id == file.id)
			state.files[state.currentFolder].splice(deleteIndex, 1)
		},
		GET_STARTED: state => state.getStarted = true,
		SET_CRUMBS: (state, array) => state.crumbs = array,
		CLEAR_CRUMBS: (state) => state.crumbs = [],
		LOAD: (state, is = null) => is !== null ? state.loading = Boolean(is) : state.loading = !state.loading,
		PAGE_BUTTONS(state, buttons) {
			state.pageButtons = buttons
		},
		SET_APP(state, data) {
			state.theApp = data
		},
		cancelUpload: (state) => {
			state.uploadDialog = false
		},
		uploadDialog: (state, folders = []) => {
			state.uploadDialog = true
			state.folders = folders
		},
		SET_INSTANCE: (state, data) => {
			state.instance = data
			state.cats = data.activeApps
		},
		changeFolder(state, key) {
			state.currentFolder = key
		},
		SHOW_SEARCH(state, data) {
			state.showFile = data
		},
		SET_APPDATA(state, data) {
			if (!data || !Object.keys(data).length) data = {}
			if (!data['/']) data['/'] = []
			state.files = data
			state.currentFolder = state.currentFolder !== '/' ? state.currentFolder : '/'
		},
		UPLOADED_FILES(state, data) {
			let folder = data[0].folder
			if (!state.files[folder]) {
				Vue.set(state.files, folder, data);
				state.currentFolder = folder;
				return;
			}

			let oldFiles = state.files[folder]
			data = [...oldFiles, ...data]
			Vue.set(state.files, folder, data)
			state.currentFolder = folder

		},
		BULK_DELETE(state, data) {
			data.forEach(id => {
				let index = state.files[state.currentFolder].findIndex(x => x.id == id)
				if (index != -1) state.files[state.currentFolder].splice(index, 1)
			})
		},
		DELETE_FILE(state, data) {
			let id = data.id
			let folder = data.folder
			if (folder.includes('/')) {
				let slash = folder.indexOf('/')
				folder = folder.slice(slash + 1)
				if (folder == '') folder = '/'
			} else {
				folder = '/'
			}
			if (state.files[folder]) {
				let index = state.files[folder].findIndex(x => x.id === id)
				state.files[folder].splice(index, 1)
				let newArray = state.files[folder].filter(x => x.id !== id)
				return Vue.set(state.files, folder, newArray)
			}
		},
		DELETE_FOLDER(state, data) {
			let newObj = JSON.parse(JSON.stringify(state.files))
			delete newObj[data]
			state.files = newObj
			if (state.currentFolder == data) {
				state.currentFolder = '/'
			}
		},
		SET_SETTINGS(state, data) {
			let temp = {}
			if (data.userApp == null) {
				temp.userApp = {
					options: {
						seenIntro: false
					}
				}
			} else {
				temp = data
			}
			state.allSettings = temp
		},
		SET_FILE_STRUCTURE(state, data) {
			state.fileStructure = data;
		}
	},
	getters: {
		currentFiles: (state) => {
			let files = []
			files = state.files[state.currentFolder] || [];
			return files.map((file, index) => {
				return {
					id: index,
					...transformFile(file)
				}
			})
		},
		folders: (state) => {
			return [...Object.keys(state.files).sort((a, b) => {
				if (a > b) return 1
				else return -1
			}).map(x => ({
				label: x === '/' ? 'Main' : x,
				value: x,
				files: state.files[x].length
			}))]

		},
		allFiles: state => {
			let files = []
			Object.values(state.files).forEach(array => {
				files = [...files, ...array]
			})
			return files
		},
		fileObj: (state, getters) => {
			let files = {}
			getters.allFiles.forEach(file => {
				files[file.id] = file
			})
			return files
		},
		currentFolderObj: (state, getters) => {
			return getters.folders.find(x => x.value === state.currentFolder)
		}
	},
	actions: {
		SET_APPDATA: async ({ state, commit, dispatch }, data) => {
			if (data) {
				// commit('SET_APPDATA', data)
			}
		},

		GET_FILE_STRUCTURE: async ({ state, commit }) => {
			try {
				commit('LOAD', true)
				let { data } = await $api.get(`/hives/:instance/file-navigation`)

				const folderStructure = generateFolderStructure(data)
				commit('SET_FILE_STRUCTURE', folderStructure)
				const fileStructure = generateFolderFiles(data)
				commit('SET_APPDATA', fileStructure)
				commit('LOAD', false)
			} catch (error) {
				commit('LOAD', false)
				console.error('Error while fetching file structure', error)
			}
		}
	},
})
