<template>
    <div>
        <LeftNavBar
            :title="selectedAppInitial"
            :accordion="true"
            @optionclick="chosen"
            class="f1"
            :items="[]"
            @chosen="fileClicked"
            @opened="parentClicked"
            @accordion="childClicked"
            :hasChild="true"
            :selectedValue="$store.state.currentFolder"
			:treeData="treeData"
			:treeDataAction="treeDataAction"
			:selectedKeys="[currentFolder]"
        >
        </LeftNavBar>
        <a-modal
            :visible="visible"
            centered
            :footer="null"
            @cancel="visible = !visible"
        >
            <h4>Rename</h4>
            <a-form>
                <a-form-item>
					<a-input
						:placeholder="oldFolderName"
						v-model="newName"
						:addon-before="`${parentFolder.replace('/', '')}`"
					>
					</a-input>
                </a-form-item>
            </a-form>
            <div style="text-align: end">
                <a-button
                    @click="visible = !visible"
                    class="mr-3 cancel-button"
                    size="large"
                    >CANCEL</a-button
                >
                <a-button @click="renameFolder" type="primary" size="large"
                    >CONFIRM</a-button
                >
            </div>
        </a-modal>
    </div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";
export default {
    name: "menu-left",
    components: { LeftNavBar },
    data() {
        return {
            visible: false,
            newName: "",
            renameObj: "",
			treeDataAction: [
				{
					label:'Rename',
					value:'rename'
				},
				{
					label:'Delete',
					value:'delete'
				}
			]
		}
    },
    watch: {
        currentFolder: {
            handler(val) {
                if (val != "") {
                    if (val == "/") {
                        this.$store.commit("SET_CRUMBS", [
                            { name: "Main" },
                        ]);
                    } else {
						const crumbs = val.split('/').map(f => { return {name: f }})
						this.$store.commit("SET_CRUMBS", [
							...crumbs
						]);
                    }
                }
            },
        },
    },
    computed: {
		parentFolder() {
			const index = this.renameObj.lastIndexOf('/');
			return index < 0 ? this.renameObj : this.renameObj.substring(0, index)
		},
		oldFolderName(){
			const index = this.renameObj.lastIndexOf('/');
			return index < 0 ? this.renameObj : this.renameObj.substring(index+1)
		},
		treeData() {
			return this.filterFileStructure(this.$store.state.fileStructure || [])
		},
		selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "CAM"
            );
        },
        currentFolder() {
            return this.$store.state.currentFolder;
        },
        folders() {
            return this.$store.getters.folders;
        },
        files() {
            return this.$store.getters.allFiles;
        },
        instance() {
            return this.$store.state.instance;
        },
		loading() {
            return this.$store.state.loading;
        },
    },
    methods: {
		filterFileStructure(data = []) {
			const result = []

			data.forEach(d => {
				if(d.title !== '+ New Folder') {
					const child = this.filterFileStructure(d.children)
					result.push({
						title: d.title,
						key: d.value,
						children: child
					})
				}
			})
			return result;
		},

        renameFolder() {
            this.$store.commit("LOAD", true);
			const newFolderName = (this.parentFolder + '/' + this.newName).replace('/', '');
            this.$api
                .put(`/upload/files/renameFolder/${this.instance.id}`, {
                    from: this.renameObj.replace('/', ''),
                    to: newFolderName,
                })
                .then((data) => {
                    this.visible = false;
					this.$store.dispatch('GET_FILE_STRUCTURE');
                    this.renameObj = "";
                    this.newName = "";
					this.$store.commit("changeFolder", newFolderName);
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
        },
        chosen(e) {
			e.itemValue = "/" + e.itemValue;
            if (e.optionValue == "rename") {
                if (e.itemValue == "/") {
                    return this.$message.error("You cannot rename Main Folder");
                } else {
                    this.visible = true;
                    this.renameObj = e.itemValue;
                }
            }
            if (e.optionValue == "delete") {
                if (this.$p < 20)
                    return this.$message.error(
                        "You do not have access to delete files"
                    );
                let folder = e.itemValue;
                if (e.itemValue == "/")
                    return this.$message.error("You cannot delete Main Folder");
                let deleteArray = [];
                this.files.forEach((file) => {
                    if (file.folder && file.folder.startsWith(e.itemValue)) {
                        deleteArray.push(file);
                    }
                });
                const finishLoading = () => {
                    self.$store.commit("DELETE_FOLDER", folder);
                    this.timerId = undefined;
					this.$store.dispatch('GET_FILE_STRUCTURE');
					this.$store.commit("changeFolder", '/');
                };

                let self = this;
                this.$confirm({
                    title: "Delete Folder",
                    content: (h) => (
                        <div>Do you want to delete this folder?</div>
                    ),
                    okText: "Delete",
                    okType: "danger",
                    cancelText: "Cancel",
                    centered: true,
                    onOk() {
                        let ids = deleteArray.map((x) => x.id);
                        self.$store.commit("LOAD", true);
                        self.$api
                            .put(`/upload/files/bulk/:instance`, ids)
                            .then(({ data }) => {
                                if (data.success) {
									self.$store.commit("LOAD", false)
                                    self.$store.commit("BULK_DELETE", ids);
                                    self.timerId = setTimeout(function () {
										finishLoading();
                                        self.timerId = undefined;
                                    }, 500);
                                } else {
                                    self.$store.commit("LOAD", true);
                                    return self.$message.error(
                                        "Something went wrong"
                                    );
                                }
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									self.$message.error(self.$err(err))
								}
							});
                    },
                    class: "test",
                });
            }
            if (e.optionValue == "download") {
				// Todo
            }
        },
        parentClicked(e) {
            // this.$store.commit('changeFolder', e.key)
        },
        fileClicked(e) {
        },
        childClicked(e) {
            this.$store.commit("changeFolder", e.value);
        },
    },
    created() {
		const currentFolder = this.$store.state.currentFolder;
        if (currentFolder != "") {
            if (currentFolder == "/") {
                this.$store.commit("SET_CRUMBS", [
                    { name: "Main" },
                ]);
            } else {
				const crumbs = currentFolder.split('/').map(f => { return {name: f }})
                this.$store.commit("SET_CRUMBS", [
                    ...crumbs,
                ]);
            }
        }
    },
};
</script>
<style>
.cancel-button.ant-btn {
    border-color: #ece9f1 !important;
    background-color: #ece9f1;
    color: #3f3356;
}
</style>
