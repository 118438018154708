<template>
  <div>
    <SubBarGlobal :currentCrumb="$route.meta.crumbName || ''">
      <a-auto-complete
      style="width: 300px"
      placeholder="Search for a file..."
      :dropdown-match-select-width="false"
      :dropdown-style="{ width: '300px' }"
      option-label-prop="value"
      @search="handleSearch"
      class="mr-4"
    >
      <template slot="dataSource">
          <a-select-option @click="optionClicked(file.id, file.folder)" v-for="(file, fileI) in searchResult" :key="fileI" :value="file.name" >
            <div class="dF aC">
              <span v-if="file.Type == 'Image'" class="mr-2"><img style="width:30px; height:30px; object-fit:cover;" :src="`${file.url}`" /></span>
              <div class="f1">
                <div><strong>{{ file.name }}</strong></div>
                <small class="text-sm">Folder: {{file.folder === '/' ? 'Main' : file.folder}}</small>
              </div>
            </div>
          </a-select-option>
      </template>
      <a-input>
        <a-icon style="stroke:#000; stroke-width:34" slot="prefix" type="search"  />
      </a-input>
    </a-auto-complete>

    <div class="mr-4 d-none d-xl-block dash-divider"></div>
    <PageButtons />
    </SubBarGlobal>
  </div>
</template>
<script>
  const search = {
          leadScore:{
            type:'_gte',
            value:1
          },
          firstName:{
            type:'_contains',
            value:'',
          },
          lastName:{
            type:'_contains',
            value:'',
          },
          email:{
            type:'_contains',
            value:'',
          },
          tagI:{
            type:'any',
            value:[]
          },
          tagE:{
            type:'any',
            value:[]
          }
    }

  import {getAppIcon, getInitial} from 'bh-mod'
  import SubBarGlobal from 'bh-mod/components/layout/SubBarGlobal'
  import PageButtons from 'bh-mod/components/layout/SubBarGlobal/PageButtons'

  export default {
    components:{SubBarGlobal,PageButtons},
    data(){
      return {
        loadSearch:false,
        search: JSON.parse(JSON.stringify(search)),
        searchResult:[],
      }
    },
    watch:{

    },
    computed:{
      files() {
        let storeFiles = this.$store.state.files;
        let fileList = []

		for (const folder in storeFiles) {
			if (Object.hasOwnProperty.call(storeFiles, folder)) {
				const files = storeFiles[folder];
				files.forEach((file, index) => {
					if (file.items) {
						if ((fileList.findIndex(x => x.name === file.name && x.id !== index) == -1)) {
							fileList.push({
								name: file.name,
								folder: folder,
								id: index
							})
						}
					} else if (fileList.findIndex(x => x.name === file.name && x.id !== file.id) == -1) {
						const folderName = file.folder.startsWith('/') ? file.folder.replace('/', '') : (file.folder || '/')

						fileList.push({
							name: file.name,
							folder: folderName,
							id: file.id
						})
					}
				})

			}
		}

        return fileList
      },
      pageButtons(){
        return this.$store.state.pageButtons
      },
      appInitial(){
        let appinit
        if (this.theApp.name == 'Models') {
          appinit="Contract Builder (HMC)"
        }
        return appinit
      },
      theApp(){
           return this.$store.state.theApp || {}
      },

      tags(){
        return this.$store.state.contracts.tags
      },
      instance(){
        return this.$store.state.instance
      },
    },
    methods:{
      async optionClicked(id, folder) {
        await this.$store.commit('changeFolder', folder)
        await document.querySelectorAll('.select').forEach(x => x.classList.remove('select'))
        if (document.querySelector(`[data-row-key="${id}"]`)){
          document.querySelector(`[data-row-key="${id}"]`).classList.add('select')
        }
      },
      handleSearch(input) {
        if (input && input.length > 2){
          this.searchResult = Object.values(this.files).filter(({name}) => {
              name = name.toLowerCase()
              input = input.toLowerCase()
              return name.includes(input)
          })
        } else {
          return this.searchResult = []
        }
      },
      getAppIcon,
      getInitial,
      searchContacts(){
        this.loadSearch = true
        this.$store.commit('SET_SEARCH',this.search)
        this.$store.commit('SET_NEW_SEARCH_TIME')
        this.$store.commit('TOGGLE_SEARCH')
      }
    },
    created(){

    }
  }
</script>

<style lang="scss">
  .ant-form-horizontal.search-horizontal{
    label{
    }
    .ant-form-item-control-wrapper{
      flex:1;
    }
    .ant-form-item{
      display:flex;
    }
    .ant-form-item-label{
      min-width:100px;
    }
  }
  .ant-table-row.ant-table-row-level-0.select{
    background-color:white;
    -webkit-animation-name: setBackground;
    -webkit-animation-iteration-count: 2s;
    -webkit-animation-duration: 2s;
  }

    @-webkit-keyframes setBackground {
    100% { background-color: rgba(247,148,30,0.2); }
  }
</style>
