<template>
  <div id="app">
    <MediaSelector />
    <BhLoading :show="loading" />
    <localization></localization>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MediaSelector from 'bh-mod/components/file/MediaSelector'
import BhLoading from 'bh-mod/components/common/Loading'
import Localization from 'bh-mod/localization'

export default {
  name: 'app',
  components: { Localization,MediaSelector,BhLoading, },
  computed: {
    loading(){
      return this.$store.state.loading
    },
    ...mapGetters(['user'])
  },
  watch: {
    '$route'(to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
}
</script>
<style>
  #app{
    min-height: 100vh;
  }
</style>
